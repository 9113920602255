import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  }
  ,
  {
    path: '/shake',
    name: 'shake',
    component: () => import(/* webpackChunkName: "about" */ '../views/ShakeView.vue')
  }
  ,
  {
    path: '/play',
    name: 'play',
    component: () => import(/* webpackChunkName: "about" */ '../views/PlayView.vue')
  }




]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
